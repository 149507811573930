import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import {
  browserTracingIntegration,
  browserProfilingIntegration,
  reactRouterV6BrowserTracingIntegration,
} from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import {
  APP_VERSION,
  ENVIRONMENT,
  // IS_ERROR_TRACKING_ENABLED,
  SENTRY_CUSTOM_TAG,
  SENTRY_CUSTOM_TAG_KEY,
  SENTRY_DSN,
  SENTRY_TRACES_SAMPLE_RATE,
  SENTRY_PROFILES_SAMPLE_RATE,
} from '@/shared/config';

import { App } from '@/app/App';

import './index.css';
import './shared/styles/styles.scss';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    browserTracingIntegration(),
    browserProfilingIntegration(),
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
  tracesSampler: (samplingContext) => {
    const customTag = samplingContext?.[SENTRY_CUSTOM_TAG_KEY];
    // const parentSampled = samplingContext.parentSampled;

    // NOTE: Currently need to track each one to find out the reason of this behaviour
    if (customTag === SENTRY_CUSTOM_TAG.refreshWidget) {
      return 1.0; // Capture 100% of traces for this message
    }

    // Continue trace decision, if there is any parentSampled information
    // if (typeof parentSampled === 'boolean') {
    //   return parentSampled;
    // }

    return SENTRY_TRACES_SAMPLE_RATE;
  },
  profilesSampleRate: SENTRY_PROFILES_SAMPLE_RATE,
  environment: ENVIRONMENT,
  release: APP_VERSION,
  enabled: true, // IS_ERROR_TRACKING_ENABLED,
  debug: true,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
