import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Code, ConnectError } from '@bufbuild/connect';

import api from '@/shared/api/api';
import { useAppDispatch } from '@/shared/hooks';
import { Patient } from '@/shared/api/protocol-ts/model/dto_patient_pb';

import { patientModel } from '@/entities/patient';
import { StudyCount, studyCountModel } from '@/entities/studyCount';

import { AllPatientsFilterSettingsType } from '@/pages/Patients/config/types';

export type UsePatientsListStreamArgs = {
  organizationID: string;
};

let abortController: AbortController;

export const usePatientsListStream = ({
  organizationID,
}: UsePatientsListStreamArgs) => {
  // TODO: Resolve issue with previous searchParams date on reopening stream
  const [searchParams] = useSearchParams();

  const doctorIDs = searchParams.get('doctorIDs') ?? '';
  const doctorFiltersIsEmpty = !searchParams.get('doctorIDs');

  const searchValue = searchParams.get('search') ?? '';
  const doctorFilter = doctorFiltersIsEmpty ? [] : doctorIDs.split(',');

  // use to restore patients state for infinity scroll
  // must be reset after any filters update
  const [resumeToken, setResumeToken] = useState('');

  const dispatch = useAppDispatch();

  const openPatientListStream = async (
    filterSettings: AllPatientsFilterSettingsType = {
      searchString: searchValue,
      doctorFilters: doctorFilter,
      id: '',
    },
  ) => {
    abortController = new AbortController();

    const { searchString, id, doctorFilters, sortBy } = filterSettings;

    dispatch(patientModel.actions.setLoading('pending'));

    try {
      const patientListStream = api.patient.patientListStream(
        {
          OrganizationID: organizationID,
          ResumeToken: resumeToken,
          SearchString: searchString,
          Doctors: {
            case: doctorFilters?.length ? 'OnlyTheseDoctors' : 'AllDoctors',
            value: doctorFilters?.length ? { DoctorIDs: doctorFilters } : {},
          },
          Sort: sortBy,
          StartFromPatientID: id,
          Limit: 20,
        },
        { signal: abortController.signal },
      );

      for await (const { Update, ResumeToken } of patientListStream) {
        switch (Update.case) {
          case 'InitialPatientsListItems': {
            let patients: Patient[] = [];
            let studyCounts: StudyCount[] = [];

            Update.value?.Patients.forEach(({ Patient, Studies }) => {
              patients.push(Patient as Patient);

              studyCounts.push({
                patientID: Patient?.ID as string,
                counts: Studies,
              });
            });

            // adding patients to store
            if (id) {
              dispatch(patientModel.actions.addMany(patients as Patient[]));
            } else {
              dispatch(patientModel.actions.setMany(patients));
            }

            dispatch(patientModel.actions.setLoading('succeeded'));

            // adding study counts to store
            dispatch(studyCountModel.actions.setMany(studyCounts));

            // clear accumulated data
            patients = [];
            studyCounts = [];
            break;
          }

          case 'PatientUpdated': {
            if (Update.value.Patient) {
              dispatch(patientModel.actions.setNewestOne(Update.value.Patient));
            }
            const studyCount = {
              patientID: Update.value.Patient?.ID,
              counts: Update.value.Studies,
            };

            dispatch(studyCountModel.actions.setOne(studyCount));

            break;
          }

          case 'SharedWithMeCount': {
            dispatch(patientModel.actions.setSharedWithMeCount(Update.value));
            break;
          }

          case 'SharedByMeCount': {
            dispatch(patientModel.actions.setSharedByMeCount(Update.value));
            break;
          }

          case 'TotalOrganizationPatientCount': {
            dispatch(
              patientModel.actions.setTotalOrganizationPatientCount(
                Update.value,
              ),
            );
          }
        }

        if (ResumeToken) {
          setResumeToken(ResumeToken);
        }
      }
    } catch (error) {
      const connectErr = ConnectError.from(error);
      // WARN: Need to test in dev
      console.error(error);
      console.log({ connectErr });
      if (error instanceof ConnectError && error.code !== Code.Canceled) {
        // TODO: [8|h] for some reason stream throw this error and works fine, need to investigate this
        // after refactoring patient list to prevent rerenders and reinit stream
        if (
          !(
            error.code === Code.Unknown &&
            error.rawMessage === 'missing request message'
          )
        ) {
          dispatch(patientModel.actions.setLoading('failed'));
        }
      }
    }
  };

  const closePatientListStream = (shouldClearStore = true) => {
    if (abortController) {
      abortController.abort();
    }

    if (shouldClearStore) {
      dispatch(patientModel.actions.removeAll());
    }
  };

  return { openPatientListStream, closePatientListStream };
};
